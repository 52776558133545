var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"customForm",attrs:{"rules":_vm.validateRules,"model":_vm.formInfo},on:{"validate":_vm.handleValidation},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.formInfo),function(field,k){return _c('el-form-item',{key:k,attrs:{"prop":k}},[(field.type === 'password')?_c('el-input',{staticClass:"pwd-input",style:(_vm.isLogin
                    ? ''
                    : _vm.showPassword[k]
                    ? ''
                    : '-webkit-text-security: disc'),attrs:{"type":_vm.isLogin ? (_vm.showPassword[k] ? 'text' : 'password') : 'text',"placeholder":field.customText
                    ? field.customText
                    : _vm.inputTypes[k].placeholder},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},[_c('eye',{attrs:{"slot":"suffix","isText":_vm.showPassword[k]},on:{"changeStatus":function($event){_vm.showPassword[k] = !_vm.showPassword[k]}},slot:"suffix"})],1):(field.type === 'captcha')?_c('el-input',{staticClass:"input-captcha",attrs:{"type":"text","placeholder":_vm.$t('dm_login.captcha_pl')},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},[(!_vm.isRefresh)?_c('img',{attrs:{"slot":"suffix","loading":"lazy","src":_vm.captchaUrl,"width":"120px","height":"48px","alt":"captcha"},on:{"click":_vm.refreshCaptcha},slot:"suffix"}):_vm._e()]):_c('el-input',{staticClass:"input-text",attrs:{"type":"text","placeholder":_vm.inputTypes[k].placeholder},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),(field.slotName)?_vm._t(field.slotName):_vm._e()],2)}),_vm._t("beforeBtn"),_c('el-form-item',[_c('div',{staticClass:"btn-container"},[_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"p-btn usable",attrs:{"native-type":"submit"},on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.isLoading ? "" : _vm.confirmText))]),_c('div',[_c('p',{staticClass:"success-tip"},[_vm._v(_vm._s(_vm.successTip))]),_c('p',{staticClass:"error-tip"},[_vm._v(_vm._s(_vm.errorTip))])]),_vm._t("beforeOr"),_c('p',{staticClass:"or"},[_c('span',[_vm._v(_vm._s(_vm.$t("dm_login.or")))])]),_c('p',{staticClass:"p-btn black",on:{"click":function($event){return _vm.$navigateTo($event, _vm.bottomLink.href)}}},[_vm._v(" "+_vm._s(_vm.bottomLink.text)+" ")])],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }